import styled from "styled-components"
import { motion } from "framer-motion"
import { breakpoints } from "../../../utils/styles"

export const Section = styled.div`
  background: ${({ theme }) => theme.color.backgroundSectionOne};
  overflow: hidden;
  padding: 96px 32px;

  @media (max-width: ${breakpoints.l}px) {
    padding: 64px 24px;
  }
`

export const Content = styled(motion.div)`
  color: ${({ theme }) => theme.color.black};
  max-width: 900px;
  min-height: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: ${breakpoints.l}px) {
    min-height: 400px;
  }

  @media (max-width: ${breakpoints.s}px) {
    text-align: left;
    align-items: flex-start;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
`

export const Image = styled.img`
  width: 100%;
  margin-bottom: 48px;
`

export const WaterFlow = styled(motion.img)`
  position: absolute;
  top: -18%;
  left: -1%;

  z-index: 1;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    top: -16%;
  }
`

export const LeftTire = styled(motion.img)`
  position: absolute;
  top: 29%;
  left: 28.6%;
  width: 5%;
  z-index: 1;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    top: 27%;
  }
`

export const RightTire = styled(motion.img)`
  position: absolute;
  top: 25%;
  left: 37%;
  width: 6%;
  z-index: 1;
  margin: 0;

  @media (max-width: ${breakpoints.l}px) {
    top: 24%;
  }

  @media (max-width: ${breakpoints.m}px) {
    top: 22%;
  }
`

export const Description = styled.p`
  font-size: 24px;
  line-height: 32px;

  @media (max-width: ${breakpoints.l}px) {
    font-size: 16px;
    line-height: 24px;
  }
`
