import React from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Splash from "../components/sections/Splash"
import ConflictCards from "../components/sections/ConflictCards"
import BridgeParallax from "../components/sections/BridgeParallax"
import CoverImage from "../components/sections/CoverImage"
import TextImage from "../components/sections/TextImage"
import VideoText from "../components/sections/VideoText"
import VideoGrid from "../components/sections/VideoGrid"
import DataGrid from "../components/sections/DataGrid"

const BosniaPage = ({ data }) => {
  const intl = useIntl()
  const { locale } = intl
  const { markdownRemark, allFile } = data
  const {
    id,
    title,
    startYear,
    endYear,
    firstVideoTitle,
    firstVideoText,
    secondVideoTitle,
    secondVideoText,
    thirdVideoTitle,
    thirdVideoText,
    fourthVideoTitle,
    fourthVideoText,
    sectionTitle,
    sectionText,
    homeSectionTitle,
    homeSectionText,
  } = markdownRemark.frontmatter

  // Images
  const firstImage = allFile.nodes.find(image => image.name === `${id}_image_1`)

  const secondImage = allFile.nodes.find(
    image => image.name === `${id}_image_2`
  )

  const thirdImage = allFile.nodes.find(image => image.name === `${id}_image_3`)

  const fourthImage = allFile.nodes.find(
    image => image.name === `${id}_image_4`
  )

  // Video images
  const firstVideoImage = allFile.nodes.find(
    image => image.name === `${id}_1_${locale}`
  )
  const secondVideoImage = allFile.nodes.find(
    image => image.name === `${id}_2_${locale}`
  )
  const thirdVideoImage = allFile.nodes.find(
    image => image.name === `${id}_3_${locale}`
  )
  const fourthVideoImage = allFile.nodes.find(
    image => image.name === `${id}_4_${locale}`
  )

  // Video paths
  const videoSrc = {
    first: {
      en: '533049335',
      sv: '533053700'
    },
    second: {
      en: '533049263',
      sv: '533053615'
    },
    third: {
      en: '533053561',
      sv: '533049220'
    },
    fourth: {
      en: '533053424',
      sv: '533049129'
    },
  }

  // Videos
  const videos = [
    {
      id: 1,
      video: videoSrc.second[locale],
      image: secondVideoImage,
      title: secondVideoTitle,
      text: secondVideoText,
    },
    {
      id: 2,
      video: videoSrc.third[locale],
      image: thirdVideoImage,
      title: thirdVideoTitle,
      text: thirdVideoText,
    },
    // {
    //   id: 3,
    //   video: videoSrc.fourth[locale],
    //   image: fourthVideoImage,
    //   title: fourthVideoTitle,
    //   text: fourthVideoText,
    // },
  ]

  // Conflict stats
  const conflictStats = [
    {
      id: 1,
      value: "38599",
      text: intl.formatMessage({ id: "conflict.un_participants" }),
    },
    {
      id: 2,
      value: "96850",
      text: intl.formatMessage({ id: "conflict.data_deaths" }),
    },
    {
      id: 3,
      value: "2200000",
      text: intl.formatMessage({ id: "conflict.data_on_run" }),
    },
  ]

  return (
    <Layout>
      <SEO title={title} />
      <Splash
        title={title}
        year={`${startYear} - ${endYear}`}
        image={firstImage}
        color="white"
      />
      <DataGrid
        data={conflictStats}
        backgroundColor="#869FAD"
        textColor="white"
      />
      <VideoText
        title={firstVideoTitle}
        description={firstVideoText}
        image={firstVideoImage}
        video={videoSrc.first[locale]}
        backgroundColor="white"
        textColor="black"
      />
      <BridgeParallax title={sectionTitle} description={sectionText} />
      <CoverImage image={secondImage} />
      <VideoGrid videos={videos} backgroundColor="#5B4F53" textColor="white" />
      <CoverImage image={thirdImage} />
      <TextImage
        title={homeSectionTitle}
        description={homeSectionText}
        backgroundColor="white"
        large
      />
      <CoverImage image={fourthImage} />
      <ConflictCards
        title={intl.formatMessage({ id: "conflict.discover_title" })}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BosniaQuery($locale: String) {
    markdownRemark(
      frontmatter: { lang: { eq: $locale }, id: { eq: "bosnia" } }
    ) {
      html
      frontmatter {
        id
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        startYear
        endYear
        firstVideoTitle
        firstVideoText
        secondVideoTitle
        secondVideoText
        thirdVideoTitle
        thirdVideoText
        fourthVideoTitle
        fourthVideoText
        sectionTitle
        sectionText
        homeSectionTitle
        homeSectionText
      }
    }
    allFile(filter: { relativeDirectory: { eq: "conflicts" } }) {
      nodes {
        id
        name
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export default BosniaPage
