import React, { useLayoutEffect } from "react"
import PropTypes from "prop-types"
import ReactHtmlParser from "react-html-parser"
import { useInView } from "react-intersection-observer"
import { useAnimation } from "framer-motion"

import Heading, { Type } from "../../UI/Heading"

import {
  Section,
  Content,
  Image,
  WaterFlow,
  Description,
  ImageContainer,
  LeftTire,
  RightTire,
} from "./styles"

import bridgeImage from "../../../assets/conflicts/bridge/starimost.png"
import waterImage from "../../../assets/conflicts/bridge/water.png"
import tireImage1 from "../../../assets/conflicts/bridge/tire1.png"
import tireImage2 from "../../../assets/conflicts/bridge/tire2.png"

const contentVariants = {
  visible: { scale: 1, opacity: 1 },
  hidden: { scale: 1.2, opacity: 0 },
}

const wheelOneAnimation = {
  x: ["-0.4vw", "0.4vw", "-0.4vw"],
  rotate: [12, -12, 12],
}

const wheelTwoAnimation = {
  x: ["0.2vw", "-0.2vw", "0.2vw"],
  rotate: [-4, 4, -4],
}

const wheelTransition = {
  duration: 3,
  delay: 1,
  ease: "easeInOut",
  repeat: Infinity,
}

const BridgeParallax = ({ title, description }) => {
  const [bridgeRef, inBridgeView] = useInView()
  const [contentRef, inContentView] = useInView()
  const contentControls = useAnimation()
  const wheelControls = useAnimation()

  useLayoutEffect(() => {
    if (inBridgeView) {
      wheelControls.start("visible")
    }
  }, [wheelControls, inBridgeView])

  useLayoutEffect(() => {
    if (inContentView) {
      contentControls.start("visible")
    }
  }, [contentControls, inContentView])

  return (
    <Section>
      <ImageContainer ref={bridgeRef}>
        <LeftTire
          transition={wheelTransition}
          animate={wheelOneAnimation}
          src={tireImage1}
          alt="left tire"
        />
        <RightTire
          transition={wheelTransition}
          animate={wheelTwoAnimation}
          src={tireImage2}
          alt="right tire"
        />
        <WaterFlow src={waterImage} alt="water" />
        <Image src={bridgeImage} alt="bridge" />
      </ImageContainer>
      <Content
        ref={contentRef}
        animate={contentControls}
        transition={{ duration: 0.8 }}
        variants={contentVariants}
        initial="hidden"
      >
        <Heading type={Type.h2}>{title}</Heading>
        <Description>{ReactHtmlParser(description)}</Description>
      </Content>
    </Section>
  )
}

BridgeParallax.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default BridgeParallax
